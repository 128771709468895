import React from "react";
import { TextArea, Form, Button, Dropdown, Grid, Segment, Icon, Message, Header, Progress, Loader, Label, Popup, Input, } from 'semantic-ui-react';
import { present, errorFor, formInput, adaptOnChangeToClassicFormOnChange, selectGenderedSalutes, } from "../shared/utils.js";
import FixedOptionsFormInput from "../shared/FixedOptionsFormInput.js";
import _ from "underscore"
import axios from "axios";
import TextAreaWithInsertableVariables from "../components/TextAreaWithInsertableVariables.js"

export default class CampaignMessagesForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      messageWithFocus: null,
    }
    this.messageRefs = {};
    this.handleMessageChange = this.handleMessageChange.bind(this)
    this.handleEmptyInvitationsChange = this.handleEmptyInvitationsChange.bind(this)
  }

  componentDidMount(_prevProps, _prevState) {
    this.handleEmptyInvitationsChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sendsEmptyInvitations !== this.props.sendsEmptyInvitations) {
      this.handleEmptyInvitationsChange();
    }
  }

  handleEmptyInvitationsChange() {
    let newFollowUps = [...this.props.messages.followUps]

    if (this.props.sendsEmptyInvitations && newFollowUps[0]?.daysDelay === "0") {
      newFollowUps[0].daysDelay = "2";
    }

    this.props.onMessagesChange({ message: this.props.messages.message, followUps: newFollowUps });
  }

  handleAddFollowUp() {
    let newFollowUps = [...this.props.messages.followUps]
    newFollowUps.push({
      message: "",
      daysDelay: this.defaultDaysDelay(newFollowUps),
      sendVia: "linkedin",
    });
    this.props.onMessagesChange({ message: this.props.messages.message, followUps: newFollowUps });
  }

  defaultDaysDelay(newFollowUps) {
    let l = newFollowUps.length

    if (this.props.sendsEmptyInvitations && l === 0) {
      return "2";
    }
    if (!this.props.sendsEmptyInvitations && l === 0) {
      return "0";
    }
    if (l > 0) {
      return "14"
    }
  }

  handleRemoveFollowUp(removeIndex) {
    let newFollowUps = [...this.props.messages.followUps]
    newFollowUps = this.props.messages.followUps.filter((x, i) => i !== removeIndex)
    this.props.onMessagesChange({ message: this.props.messages.message, followUps: newFollowUps });
  }

  handleFollowUpsChange(index, event, data) {
    let newFollowUps = [...this.props.messages.followUps]
    newFollowUps[index][event.target.name] = event.target.value;
    this.props.onMessagesChange({ message: this.props.messages.message, followUps: newFollowUps });
  }

  handleMessageChange(event) {
    this.props.onMessagesChange({ ...this.props.messages, message: event.target.value, });
  }

  renderFollowUps() {
    let { messages, onFollowUpsChange, followUpName, } = this.props;
    let firstEmail = false;

    return messages.followUps.map((followUp, i) => {
      let showSubject = followUp.sendVia === "email" && !firstEmail;
      firstEmail = !firstEmail && followUp.sendVia === "email";
      let handleFollowUpsChange = (event, data) => { this.handleFollowUpsChange(i, event, data) }
      return (
        <div key={i} style={{marginBottom: 14}} data-test-id={"follow-up-"+i}>
          <Segment>
            <Grid>

              <Grid.Row>
                <Grid.Column width={12} floated="left">
                  <Header as={"h3"}>{ followUpName || "Follow up" } {i+1}</Header>
                </Grid.Column>


                <Grid.Column width={2} floated="right" style={{textAlign: "right"}}>
                  <Button type="button" onClick={() => {this.handleRemoveFollowUp(i)}} icon><Icon name="trash" /></Button>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns="equal">
                <Grid.Column>
                  <FixedOptionsFormInput
                    model={{ ...followUp, daysDelay: ""+followUp.daysDelay }}
                    name="daysDelay"
                    label="When"
                    onChange={handleFollowUpsChange}
                    Element={Form.Select}
                    props={
                      {
                        options: [
                          {key: "0", value: "0", text: "as soon as possible", disabled: this.props.sendsEmptyInvitations || i > 0},
                          {key: "1", value: "1", text: "after one day", },
                          ...(_.range(2,90).map(i => { return {key: ""+i, value: ""+i, text: `after ${i} days`, } }))
                        ],
                      }
                    }
                  />
                </Grid.Column>
                <Grid.Column>
                  <FixedOptionsFormInput
                    model={{...followUp}}
                      name="sendVia"
                      label="Send as..."
                      onChange={handleFollowUpsChange}
                      Element={Form.Select}
                      props={
                        {
                          options: [
                            {key: "linkedin", value: "linkedin", text: "LinkedIn message"},
                            {key: "email", value: "email", text: "Email", },
                          ],
                        }
                      }
                    />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  { followUp.sendVia === "email" && showSubject &&
                      formInput(
                        { ...followUp, daysDelay: ""+followUp.daysDelay },
                        "subject",
                        "Subject",
                        handleFollowUpsChange,
                      )
                  }
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <EditorWithTemplateVarsAndPreview
              ourName={this.props.ourName}
              ourShortFirstName={this.props.ourShortFirstName}
              asPlaintext={followUp.sendVia === "linkedin"}
              name={"message"}
              model={followUp}
              labelContent={"Message"}
              onChange={handleFollowUpsChange}
              style={{ minHeight: 300 }}
              maxChars={this.props.maxConnectionMessageLength}
              genderedSalutes={this.props.genderedSalutes}
              gmailMailboxId={this.props.gmailMailboxId}
              renderTemplateVariables={this.props.renderTemplateVariables}
              renderPreview={this.props.renderPreview}
              editable={this.props.allowEditingFromStage <= i+1}
              customPromptVariables={this.props.customPromptVariables}
              isFollowUp={true}
            />
            { present(followUp.messages) && followUp.messages.length > 0 && <span>{followUp.messages.join("; ")}</span> }

        </Segment>
      </div>
      );
    });
  }

  render() {
    let { messages, onChange, genderedSalutes, headlineFirstMessage, } = this.props;

    return (
      <div>
        <Segment data-test-id="connection-message">
          <Header as={"h3"}>{ headlineFirstMessage || "Connection Message" }</Header>
          <EditorWithTemplateVarsAndPreview
            ourName={this.props.ourName}
            ourShortFirstName={this.props.ourShortFirstName}
            asPlaintext={true}
            model={messages}
            name={"message"}
            labelContent={"Message"}
            onChange={this.handleMessageChange}
            maxChars={this.props.maxConnectionMessageLength}
            genderedSalutes={genderedSalutes}
            renderTemplateVariables={this.props.renderTemplateVariables}
            customPromptVariables={this.props.customPromptVariables}
            renderPreview={this.props.renderPreview}
            editable={this.props.allowEditingFromStage === 0}
          />
        </Segment>

        { this.renderFollowUps() }

        <Button type="button"
          onClick={this.handleAddFollowUp.bind(this)}
          icon="add"
          labelPosition="left"
          content={"Add follow up"}
        />
      </div>
    );
  }
}

class EditorWithTemplateVarsAndPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tookFocus: false,
    }

    this.handlePlaintextStateChange = this.handlePlaintextStateChange.bind(this);
  }

  plaintextState() {
    return this.props.model[this.props.name];
  }

  handlePlaintextStateChange(newState) {
    const { value, } = newState;

    this.props.onChange({
      target: {
        value,
        name: "message",
      }
    })
  }

  render () {
    let { asPlaintext, genderedSalutes, model, name, labelContent, renderTemplateVariables, } = this.props
    let error = errorFor(model, name)

    let mappedSalutes = selectGenderedSalutes(genderedSalutes)

    let variables = []
    if (renderTemplateVariables) {
      variables = variables.concat(mappedSalutes);
    }
    console.log("custom prompt variables", this.props.customPromptVariables)
    if (this.props.customPromptVariables && this.props.editable) {
      variables = variables.concat(this.props.customPromptVariables.sort((a, b) => (a.shortcut > b.shortcut) ? 1 : -1))
    }
    return (
      <React.Fragment>
        <Grid>
          <Grid.Column width={this.props.renderPreview ? 8 : 16}>
            <Form.Field error={present(error)}>
              <label>{ labelContent }</label>
              <PlaintextOrWysiwygEditor
                variables={variables}
                name={name}
                onPlaintextStateChange={this.handlePlaintextStateChange}
                plaintextState={this.props.model[this.props.name]}
                editable={this.props.editable}
                maxChars={this.props.maxChars}
                renderPreview={this.props.renderPreview}
                handleInsertTemplateVar={this.handleInsertTemplateVar}
                isFollowUp={this.props.isFollowUp}
              />
              { present(error) && <Label pointing prompt>{error}</Label> }
            </Form.Field>
          </Grid.Column>
          { this.props.renderPreview &&
            <Grid.Column width={8}>
              <MessagePreview
                ourName={this.props.ourName}
                ourShortFirstName={this.props.ourShortFirstName}
                asPlaintext={asPlaintext}
                template={this.plaintextState()}
                gmailMailboxId={this.props.gmailMailboxId}
                subject={this.props.model.subject}
              />
            </Grid.Column>
          }
        </Grid>
      </React.Fragment>
    );
  }
}

class PlaintextOrWysiwygEditor extends React.Component {

  constructor(props) {
    super(props)
    this.handleTextAreaActivity = this.handleTextAreaActivity.bind(this)
  }

  handleTextAreaActivity(event, data, cb) {
    this.props.onPlaintextStateChange({
      value: event.target.value,
    }, cb)
  }

  render() {
    let { name, onFocus, style, renderPreview, isFollowUp } = this.props;
    let messageLength = this.props.plaintextState ? this.props.plaintextState.length : 0;

    return (
      <React.Fragment>
        <div>
          <TextAreaWithInsertableVariables
            variables={this.props.variables}
            onFocus={onFocus}
            style={ { minHeight:300, ...style }}
            value={this.props.plaintextState}
            onChange={this.handleTextAreaActivity.bind(this)}
            name={name}
            disabled={!this.props.editable}
          />
          <div>
            {!renderPreview && !isFollowUp && (
                <p
                  style={{
                    float: 'left',
                    color: messageLength > this.props.maxChars && 'red',
                    marginTop: 8,
                    lineHeight: '10px'
                  }}
                >
                  { present(this.props.maxChars) && <span>{messageLength} / {this.props.maxChars}</span> }
                </p>
            )}
          </div>

        </div>
      </React.Fragment>
    );


  }
}

class TestMail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      to: "",
      loading: false,
      firstRequestDone: false,
      error: null,
    };
    this.sendTestEmail = this.sendTestEmail.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
  }

  handleEmailChange(_event, { value }) {
    this.setState({to: value});
  }

  sendTestEmail() {
    this.setState({loading: true, error: null, })
    axios.post(`/backend/gmail_mailboxes/${this.props.gmailMailboxId}/test_email/`, {
      message: this.props.message,
      subject: this.props.subject,
      to: this.state.to,
      data: {
        name: "Magdalena Musterfrau",
      },
    })
    .then(({data}) => {
      this.setState({
        loading: false,
        error: data.error,
        success: true,
        firstRequestDone: true,
      });
    })
    .catch(error => {
      console.log(error);
      this.setState({
        loading: false,
        error: error.message,
        firstRequestDone: true,
      })
    });
  }

  render() {
    return (
      <Popup trigger={<Button size="mini">Test Mail</Button>} on="click">
        { !present(this.props.gmailMailboxId) && <Message>Please select mailbox for this campaign.</Message> }
        { present(this.props.gmailMailboxId) && <React.Fragment>
            <Header as='h5'>Send Test Mail</Header>
            { this.state.error && <Message negative><p>{this.state.error}</p></Message> }
            { this.state.firstRequestDone && !this.state.error && !this.state.loading && <Message success>Message sent</Message> }
            <Form onSubmit={this.sendTestEmail}>
              <Input type="text" action placeholder="Email..." value={this.state.to} onChange={this.handleEmailChange} style={{flex: 1}}>
                <input style={{flex:1 }} />
                <Button type="submit" loading={this.state.loading}>Send</Button>
              </Input>
            </Form>
          </React.Fragment>
        }
      </Popup>
    );
  }
}

class MessagePreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preview: "",
      charCount: 0,
      loading: true,
      error: null,
    }
    this.debouncedLoadPreview = _.debounce(this.loadPreview.bind(this), 100)
  }

  componentDidMount() {
    this.debouncedLoadPreview();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.template !== this.props.template) {
      this.debouncedLoadPreview();
    }
  }

  loadPreview() {
    if (this.lastCancelSource) { this.lastCancelSource.cancel(); }

    if (!present(this.props.template)) {
      this.setState({
        preview: "",
        charCount: 0,
        error: null,
        loading: false,
      });
      return;
    }

    this.lastCancelSource = axios.CancelToken.source();

    this.setState({loading: true})
    axios.post("/api/populate_message/", {
      message: this.props.template,
      our_name: this.props.ourName,
      our_short_first_name: this.props.ourShortFirstName,
      data: {
        name: "Magdalena Musterfrau",
      },
    }, {
      cancelToken: this.lastCancelSource.token,
    })
      .then(({data}) => {
        this.setState({
          loading: false,
          preview: data.message,
          charCount: data.charCount,
          error: null,
        });
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log("canceled");
          return;
        }
        console.log(error);
        this.setState({
          loading: false,
          error: error.message,
        })
      });
  }


  render() {
    return (
      <div style={{position: "relative"}}>
        <Form.Field>
          <label>
            Preview ({this.state.charCount})&nbsp;&nbsp;
            <Loader size='mini' className="showAfterOneSecond" active={this.state.loading} inline />
          </label>
          { this.props.asPlaintext && <div style={{whiteSpace: "pre-wrap"}}>{this.state.preview}</div> }
          { !this.props.asPlaintext && <div dangerouslySetInnerHTML={{__html: this.state.preview }} /> }
        </Form.Field>
        { !this.props.asPlaintext && <div style={{ position: "absolute", top: 0, right: 0 }}>
          <TestMail
            subject={this.props.subject}
            message={this.props.template}
            gmailMailboxId={this.props.gmailMailboxId}
          />
        </div> }
      </div>
    );
  }
}
