import React from "react";
import axios from "axios";
import { present, customPromptVariablesSelector, } from "../shared/utils.js";
import { withRouter, } from "react-router-dom";

import ManuallyRequestedLinkedInOutreachFormPresentation from "./ManuallyRequestedLinkedInOutreachFormPresentation.js"

const uuidv1 = require('uuid/v1');

export default class ManuallyRequestedLinkedInOutreachForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.defaultState(),
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmitAndClose = this.handleSubmitAndClose.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleForceClose = this.handleForceClose.bind(this)
    this.handleMessagesChange = this.handleMessagesChange.bind(this)
    this.debouncedSetCustomPromptVariables = _.debounce(this.setCustomPromptVariables.bind(this), 100)
  }

  async componentDidMount() {
    if (this.props.fetchRequestedOutreach) {
      await this.fetchRequestedOutreach();
      await this.fetchTrackingId(() => {
        this.debouncedSetCustomPromptVariables();
      });
    }
  }

  async fetchTrackingId(cb) {
    if (present(this.state.requestedOutreach.trackingId)) {
      console.log("not fetching tacking id because tracking id is",  this.state.requestedOutreach.trackingId)
      cb && cb()
      return;
    }
    let response = await axios.get(`/manually_requested_linked_in_outreaches/new_tracking_id`)
    this.setState({
      requestedOutreach: {
        ...this.state.requestedOutreach,
        trackingId: response.data.trackingId,
      },
    }, cb)
  }

  async componentDidUpdate(_prevProps, prevState) {
    if (prevState.requestedOutreach.campaignId !== this.state.requestedOutreach.campaignId || prevState.requestedOutreach.name !== this.state.requestedOutreach.name) {
      await this.debouncedSetCustomPromptVariables();
    }
  }

  async setCustomPromptVariables() {
    console.log("set custom prompt vars called");
    if (!this.state.requestedOutreach.campaignId) {
      await this.setState({ customPromptVariables: [] })
      return;
    }

    console.log("got thorugh return");
    const requestId = uuidv1()
    this.latestRequestId = requestId;
    let response = await axios.post(`/manually_requested_linked_in_outreaches/custom_prompt_variables`, {
      ...this.selectDataToSubmit({ ...this.state.requestedOutreach }),
    }, { headers: { requestId: requestId }, })
    console.log("setCustomPromptVariables response", response);

    if (response.config.headers.requestId !== this.latestRequestId) {
      return; 
    }


    await this.setState({ customPromptVariables: customPromptVariablesSelector(response.data.customPromptVariables) })
  }

  nameAndValueFromTarget = (target) => {
    let value = target.type && target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    value = value === "true" ? true : value;
    value = value === "false" ? false : value;

    return { name, value }
  }

  handleChange(event) {
    let requestedOutreach = { ...this.state.requestedOutreach };
    let { name, value } = this.nameAndValueFromTarget(event.target)
    // if its the first time we select a campaign and the campaign has a char limit at or below 200
    // we set the request to use empty invitaitons by default
    if (name === "campaignId" && !present(requestedOutreach[name])) {
      let campaign = this.props.campaigns.find(c => c.id == value)
      if (campaign.maxConnectionMessageLength <= 200) {
        requestedOutreach.sendAsEmptyInvitation = true
      }
    }
    requestedOutreach[name] = value
    this.setState({ requestedOutreach })
  }


  handleMessagesChange(messages) {
    this.setState({
      requestedOutreach: {
        ...this.state.requestedOutreach,
        message: messages.message,
        followUps: messages.followUps,
      }
    })
  }

  submitUrl() {
    return present(this.state.requestedOutreach.id) ? `/manually_requested_linked_in_outreaches/${this.state.requestedOutreach.id}/update` : `/manually_requested_linked_in_outreaches/create`
  }

  selectDataToSubmit(requestedOutreach) {
    delete requestedOutreach.id

    filterObject(requestedOutreach, "createdAt")
    filterObject(requestedOutreach, "updatedAt")
    filterObject(requestedOutreach, "errors")
    filterObject(requestedOutreach, "error")
    filterObject(requestedOutreach, "lockedForSendingAt")
    filterObject(requestedOutreach, "errorNotCorrectable")
    return requestedOutreach;

    function filterObject(obj, key) {
      for (var i in obj) {
        if (!obj.hasOwnProperty(i)) continue;
        if (i == key) {
          delete obj[key];
        } else if (typeof obj[i] == 'object') {
          filterObject(obj[i], key);
        }
      }
    }
  }

  defaultSendAsEmptyInvitation() {
    if (!present(this.props.defaultRequestedOutreach.campaignId)) {
      return false
    }

    let campaign = this.props.campaigns.find(c => c.id == this.props.defaultRequestedOutreach.campaignId)

    if (!present(campaign)) { return false; }

    if (campaign.maxConnectionMessageLength <= 200) {
      return true;
    }

    return false
  }

  defaultState() {
    return {
      requestedOutreach: {
        ...this.props.defaultRequestedOutreach,
        sendAsEmptyInvitation: this.defaultSendAsEmptyInvitation(),
        errors: [],
      },
      customPromptVariables: [],
      loading: false,
      errorMessage: null,
      successMessage: null,
    };
  }

  handleClose() {
    this.handleSubmitAndClose();
  }

  fetchRequestedOutreach() {
    this.setState({loading: true})
    axios.get(`/manually_requested_linked_in_outreaches/${this.props.defaultRequestedOutreach.id}.json`)
      .then(({ data }) => {
        this.setState({
          loading: false,
          requestedOutreach: {
            ...data,
            trackingId: data.trackingId || this.state.requestedOutreach.trackingId,
          }
        })
      })
      .catch(error => {
        this.setState({ loading: false, errorMessage: "Connection request not found", })
        console.error("an error occured loading prospect", error);
      })
  }

  handleSubmitAndClose(e) {
    this.setState({loading: true})
    axios.post(this.submitUrl(), {
      ...this.selectDataToSubmit({ ...this.state.requestedOutreach }),
    })
      .then(({ data }) => {
        this.setState({
          loading: false,
          requestedOutreach: data,
          successMessage: "Saved successfully",
          errorMessage: null,
        })
        if (!present(data.errors)) {
          this.props.onSave && this.props.onSave(data);
          this.props.onClose && this.props.onClose(this);
        }
      })
      .catch(error => {
        console.log("error submitting", error);
        this.setState({ loading: false, errorMessage: "We don't know why this error occured. Please try again or contact us :).", })
      });
  }

  handleForceClose() {
    this.props.onClose(this);
  }

  handleOpen() {
    this.setState({ ...this.defaultState() }, () => {
      this.fetchTrackingId(() => {
        this.debouncedSetCustomPromptVariables();
      });
    });
    this.props.onOpen && this.props.onOpen();
  }


  render () {
    let { requestedOutreach, loading, errorMessage, successMessage, customPromptVariables, } = this.state
    console.log("trackingId", requestedOutreach.trackingId);
    let { trigger, open, campaigns, renderTemplateVariables, } = this.props;
    return (
      <ManuallyRequestedLinkedInOutreachFormPresentation
        onChange={this.handleChange}
        onMessagesChange={this.handleMessagesChange}
        onSubmit={this.handleSubmitAndClose}
        onOpen={this.handleOpen}
        onClose={this.handleSubmitAndClose}
        onForceClose={this.handleForceClose}
        campaigns={campaigns}
        requestedOutreach={requestedOutreach}
        customPromptVariables={customPromptVariables}
        loading={loading}
        errorMessage={errorMessage}
        successMessage={successMessage}
        trigger={trigger}
        open={open}
        renderTemplateVariables={renderTemplateVariables}
      />
    )
  }
}

ManuallyRequestedLinkedInOutreachForm = withRouter(ManuallyRequestedLinkedInOutreachForm)
