import React from "react";
import moment from "moment";
import nl2br from "react-nl2br";
import Linkify from 'react-linkify';
import { present, } from "./utils.js";

import Attachment from "./../components/Chat/Conversation/Attachment.js";

export default class ThreadHistory extends React.Component {

  l(date) {
    return moment(date).format("DD.MM.YYYY HH:mm")
  }

  renderDate(message) {
    if (this.props.syncDetails) {
      if (present(message.toSendAfter) && !present(message.date)) {
        return (
          <span>
            {moment(message.toSendAfter).isBefore(moment()) ? (
              <>sending asap</>
            ) : (
              <>to send after: {this.l(message.toSendAfter)}</>
            )}
          </span>
        )
      } else if (present(message.sentViaDashboardAt)) {
        return (
          <span>
            dashboard sent: {this.l(message.sentViaDashboardAt)}&nbsp;/&nbsp;linkedin sent: {this.l(message.date)}
          </span>
        )
      } else {
        return (
          <span>
            linkedin sent: {this.l(message.date)}&nbsp;/&nbsp;synced at: {this.l(message.createdAt)}
          </span>
        );
      }
    } else {
      if (!message.date && moment(message.toSendAfter).isBefore(moment())) {
        return 'sending asap'
      } else if (!message.date && message.toSendAfter) {
        return `scheduled for ${this.l(message.toSendAfter)}`
      } else {
        return this.l(message.date);
      }
    }
  }

  render() {
    let { history, threadId, prospect, linkedInAccount, highlightId, syncDetails, sticky, visible } = this.props;

    if (!visible) return null;
    if (!present(history)) {
      return null;
    }

    return <div
      style={{ maxHeight: 500, width: 600, maxWidth: "none", paddingBottom: 15, paddingTop: sticky ? 0 : 60, overflowY: "scroll", position: "relative", }}
      ref={e => { if (e !== null) { e.scrollTop = e.scrollHeight } } }
    >
      <h3 style={{position: sticky ? "sticky" : "fixed", paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 10, borderBottom: "1px solid rgba(34, 36, 38, 0.15)", top: 0, left: 0, right: 0, width: "calc(100% - 18px)", background: "white", }}>
        { <a href={prospect.linkedInProfileUrl} target="_blank" className="ui" aria-label="Profile">
          <i className="fab fa-linkedin icon" style={{fontFamily: "'Font Awesome 6 Brands'", color: "#2E384D"}}></i>
        </a>
        }
        &nbsp;
        {prospect.name} <span style={{fontSize: 14}}>and {linkedInAccount.name}</span>
        &nbsp;
        <span style={{ fontSize: 12 }}><a href={`/linked_in_threads/${threadId}`} target="_blank">Chat</a></span>
      </h3>
      {
        history.map((message, i) => {
          return(
            <div
              key={i}
              style={
                {
                  marginBottom: i+1 == history.length ? 0 : 20,
                  background: present(message.id) && message.id == highlightId ? "rgba(254,243,199)" : "inherit",
                  ...(!message.date && { background: "rgba(215, 215, 215, 0.6)" }),
                  paddingLeft: 15,
                  paddingRight: 15,
                }
              }
            >
              <b>{ <a href={message.from.profileUrl}>{ message.from.name }</a> }</b>&nbsp;<i style={{fontSize: syncDetails ? 14 : 10 }}>
                { this.renderDate(message) }
              </i><br />
              <div style={{paddingLeft: 10}}>
                <Linkify properties={{target: '_blank', }}>
                { nl2br(message.text) }
                </Linkify>
                {message.attachments?.length > 0 && message.attachments.map((a) => (<Attachment
                    text={a.path}
                    attachment={a.path}
                    downloadUrl={`linked_in_threads/${threadId}/attachment/${a.path}`}
                    attachmentSize={a.size}
                  />))
                }
              </div>
            </div>
          )
        })
      }
      { this.props.children }
    </div>
  }
}
