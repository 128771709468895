import React from "react";
import filesize from "filesize";
import { Icon } from 'semantic-ui-react';

const Attachment = ({ attachment, attachmentSize, text, downloadUrl }) => {
  return (
    <div>
      <a
        target="_blank"
        href={downloadUrl}
        className="underline"
      >
        {attachment.match(/(image|\.(jpeg|png|jpg|bpm|gif|svg))$/) && (<div style={{ height: 200, width: 200, display: 'flex', justifyContent: "flex-start", alignItems: "center" }}><img loading="lazy" src={downloadUrl} style={{ width: "auto", height: "auto", maxHeight: "100%", maxWidth: "100%" }} /></div>)}
        <Icon name="file download" />
        {text.split("/").reverse()[0]}
      </a>{" "}
      ({filesize(attachmentSize)})
    </div>
  );
};

export default Attachment
